<template>
  <div>
    <takeover-buyers-query
      :loading.sync="isLoadingBuyers"
      @data="(buyers = $event) && focus()"
    />
    <takeover-suppliers-query
      :loading.sync="isLoadingSuppliers"
      @data="(suppliers = $event) && focus()"
    />

    <takeover-mutation>
      <template v-slot="{ takeoverBuyer, takeoverSupplier }">
        <div v-if="!isTakingOver">
          <div v-if="takeoverType === 'supplier'" class="filter-bar flex mb-5">
            <div class="flex-grow">
              <form autocomplete="off">
                <custom-el-select
                  class="w-full"
                  select-class="w-full"
                  :value="supplierFilter.organization_type"
                  :options="organizationTypes"
                  clearable
                  placeholder="All Organizations"
                  @input="saveFilter({ organization_type: $event })"
                />
              </form>
            </div>
            <div class="flex-grow"></div>
          </div>

          <el-autocomplete
            ref="autocomplete"
            v-model="keywords"
            class="w-full"
            :debounce="100"
            :fetch-suggestions="getSuggestions"
            :placeholder="
              `Search ${
                takeoverType === 'buyer' ? 'advertisers' : 'suppliers'
              }...`
            "
            value-key="name"
            @select="
              takeoverAccount(
                takeoverType === 'buyer' ? takeoverBuyer : takeoverSupplier,
                $event
              )
            "
          >
            <icon
              v-if="listItems.length === 0"
              slot="suffix"
              :icon="loadingIcon"
              spin
            />

            <div
              slot-scope="{ item }"
              :class="{ 'bg-light-red': item.is_test }"
            >
              {{ item.display_name }}
            </div>
          </el-autocomplete>
        </div>

        <div v-else>
          Hold on to your butts...
          <icon :icon="loadingIcon" spin />
        </div>
      </template>
    </takeover-mutation>
  </div>
</template>

<script>
import CustomElSelect from '@/components/Custom/CustomElSelect';
import { TakeoverMutation } from '@/components/Mutations';
import {
  TakeoverBuyersQuery,
  TakeoverSuppliersQuery
} from '@/components/Queries';
import { OrganizationType } from '@/constants';
import { arrowsCw as loadingIcon } from '@/vendor/icons';

export default {
  components: {
    CustomElSelect,
    TakeoverBuyersQuery,
    TakeoverMutation,
    TakeoverSuppliersQuery
  },
  props: {
    takeoverType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoadingBuyers: false,
      isLoadingSuppliers: false,
      isTakingOver: false,
      supplierFilter: {
        organization_type: [],
        ...this.getSavedFilter()
      },
      suppliers: [],
      buyers: [],
      keywords: '',
      loadingIcon
    };
  },
  computed: {
    organizationTypes() {
      return Object.values(OrganizationType).map(value => {
        return {
          value
        };
      });
    },
    listItems() {
      switch (this.takeoverType) {
        case 'supplier':
          return this.suppliers;

        case 'buyer':
          return this.buyers;

        default:
          throw new Error('Unknown Takeover type: ' + this.takeoverType);
      }
    },

    takeoverRedirect() {
      switch (this.takeoverType) {
        case 'supplier':
          return '/v3/supplier/dashboard';

        case 'buyer':
          return '/v3/buyer/dashboard';

        default:
          throw new Error('Unknown Takeover type: ' + this.takeoverType);
      }
    }
  },
  watch: {
    takeoverType() {
      // Clear the input search any time the takeover type changes
      this.clear();
    }
  },
  mounted() {
    this.focus();
  },
  methods: {
    input() {
      return this.$refs.autocomplete;
    },

    /**
     * Asynchronously load (if not already loaded) the search results and filter them
     * based on the queryString
     *
     * @param queryString
     * @param callback
     * @returns {Promise<void>}
     */
    async getSuggestions(queryString, callback) {
      // Search by the current keywords (queryString might be old)
      let results = this.listItems.filter(this.createFilter(this.keywords));

      // Highlight the first search result after they are rendered
      this.$nextTick(() => {
        if (this.input()) {
          this.input().highlight(0);
        }
      });

      callback(results);
    },

    /**
     * The filter for the queryString to search results
     *
     * @param queryString
     * @returns {Function}
     */
    createFilter(queryString) {
      return item => {
        if (!item.display_name) {
          return false;
        }

        if (this.takeoverType === 'supplier') {
          if (
            this.supplierFilter.organization_type &&
            this.supplierFilter.organization_type.length > 0
          ) {
            if (
              !this.supplierFilter.organization_type.includes(
                item.organization_type
              )
            ) {
              return false;
            }
          }
        }

        if (!queryString) {
          return true;
        }

        // id matches
        if (isFinite(+queryString) && +item.id === +queryString) {
          return true;
        }

        return (
          item.display_name.toLowerCase().indexOf(queryString.toLowerCase()) >
          -1
        );
      };
    },

    /**
     * Takeover the Supplier or Buyer account that was selected
     *
     * @param mutation
     * @param account
     */
    async takeoverAccount(mutation, account) {
      this.isTakingOver = true;

      // Clear the takeoverBuyerId, at least
      this.$store.commit('adShop/setTakeoverBuyerId', null);

      // Remove the cart id
      this.$store.commit('adShop/setAdShopCartId', null);

      // Clear the authenticated user in v4 SPA
      this.$store.commit('auth/clearAuthenticatedUser');

      await mutation(account.id);

      // XXX: For now just reload the page until we properly can reset all data for takeover
      window.location = this.takeoverRedirect;
    },

    getSavedFilter() {
      return JSON.parse(localStorage.getItem('takeover-filter'));
    },
    saveFilter(update) {
      this.supplierFilter = { ...this.supplierFilter, ...update };
      localStorage.setItem(
        'takeover-filter',
        JSON.stringify(this.supplierFilter)
      );
    },

    focus() {
      if (this.input()) {
        this.input().handleFocus();
      }
    },

    clear() {
      if (this.input()) {
        this.input().$refs.input.clear();
      }
    },

    blur() {
      if (this.input()) {
        this.input().close();
        this.input().handleBlur();
      }
    }
  }
};
</script>
