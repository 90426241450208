export const SummaryStatInfo = {
  AD_PENDING_APPROVAL: {
    title: 'National Ads Pending Approval',
    nextRoute: 'supplier.approvals'
  },
  ORDER_PENDING: {
    title: 'Insertion Orders Pending Approval',
    nextRoute: 'supplier.orders'
  },
  AD_CREATIVE_PENDING: {
    title: 'Creative Pending Approval',
    nextRoute: 'supplier.ads'
  },
  AD_UNVERIFIED: {
    title: 'Verifications Pending Upload',
    nextRoute: 'supplier.ads'
  }
};
