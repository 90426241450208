export function formatFormResponseInput(input, merge) {
  let fieldResponses = [];

  for (let fieldResponse of input.fieldResponses) {
    fieldResponses.push({
      form_field_id: fieldResponse.field.id,
      name: fieldResponse.field.name,
      value: fieldResponse.value,
      merge
    });
  }

  return {
    form_id: input.form_id,
    fieldResponses
  };
}

export function formatFormInput(input) {
  let fields = [];

  let position = 0;

  for (let fieldInput of input.fields) {
    fields.push({
      id: fieldInput.id,
      name: fieldInput.name,
      label: fieldInput.label,
      placeholder: fieldInput.placeholder,
      type: fieldInput.type,
      required: fieldInput.required,
      required_group: fieldInput.required_group
        ? '' + fieldInput.required_group
        : null,
      allow_downloads: fieldInput.allow_downloads,
      description: fieldInput.description,
      buyer_description: fieldInput.buyer_description,
      show_buyer_description: fieldInput.show_buyer_description,
      default_value: fieldInput.default_value,
      position
    });

    position++;
  }

  return {
    name: input.name,
    variations: input.variations,
    fields
  };
}
