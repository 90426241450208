<script>
import { getTakeoverBuyers } from '@/graphql/buyer/queries.graphql';

import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],

  query() {
    return {
      query: getTakeoverBuyers
    };
  }
};
</script>
